import React from 'react';
import { BlockStack, BlockStackProps } from '@shopify/polaris';

type IStackProps = BlockStackProps & {
  fillFirst?: boolean;
};

export function AppStack(props: IStackProps) {
  const { children, fillFirst } = props;
  if (!fillFirst || !Array.isArray(children)) return <BlockStack {...props} />;

  return (
    <BlockStack {...props}>
      {children && children.map((child, i) => <BlockStack key={i}>{child}</BlockStack>)}
    </BlockStack>
  );
}
