import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import { disablePromotionBannerAction } from '../../../redux/modules/status/status.actions';

interface IPromotionBannerProps {
  status: IRetailerStatus | null;
}

export const PromotionBanner = ({ status }: IPromotionBannerProps) => {
  const dispatch = useDispatch();

  const disablePromotionBanner = useCallback(() => {
    dispatch(disablePromotionBannerAction());
  }, [dispatch]);

  if (!status?.showPromotionBanner) return null;

  return (
    <div className="banner">
      <AppBanner title="Hooray! New subscription plans have arrived at Crowdship.">
        <div>
          To take advantage of lower fees and higher product limits: manage your subscription{' '}
          <AppLink url="/settings?tab=subscription-plan" onClick={disablePromotionBanner}>
            [here]
          </AppLink>
          .
        </div>
      </AppBanner>
    </div>
  );
};
