import React from 'react';
import { Redirect, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
// import { AdminToolsLayout } from './components/layouts/admin-tools-layout/admin-tools-layout';
// import { DashboardLayout } from './components/layouts/dashboard-layout/dashboard-layout';
// import { InventoryLayout } from './components/layouts/inventory-layout/inventory-layout';
// import { MarketplaceLayout } from './components/layouts/marketplace-layout/marketplace-layout';
// import { ImportListLayout } from './components/layouts/import-list-layout/import-list-layout';
// import { OrderDetailsLayout } from './components/layouts/order-details-layout/order-details-layout';
// import { OrdersLayout } from './components/layouts/orders-layout/orders-layout';
// import { PlanChangeConfirmationLayout } from './components/layouts/plans-layout/plan-change-confirmation-layout';
// import { PreferencesLayout } from './components/layouts/preferences-layout/preferences-layout';
// import { ProductSyncLayout } from './components/layouts/product-sync-layout/product-sync-layout';
// import { ProductDetailsLayout } from './components/layouts/product-details-layout/product-details-layout';
// import { MarketplaceProductDetailsLayout } from './components/layouts/marketplace-product-details-layout/marketplace-product-details-layout';
// import { PurchaseOrderDetailsLayout } from './components/layouts/purchase-order-details-layout/purchase-order-details-layout';
// import { InvoicesLayout } from './components/layouts/invoices-layout/invoices-layout';
// import { InvoiceDetailsLayout } from './components/layouts/invoice-details-layout/invoice-details-layout';
// import { RefundsDetailsPage } from '../core/components/refunds/refunds-details-page';

function RouterComponent(props: RouteComponentProps) {
  return (
    <Switch>
      {/* <Route exact path="/" component={DashboardLayout} />
      <Route exact path="/admin-tools" component={AdminToolsLayout} />
      <Route exact path="/marketplace" component={MarketplaceLayout} />
      <Route exact path="/import-list" component={ImportListLayout} />
      <Route exact path="/marketplace/:id" component={MarketplaceProductDetailsLayout} />
      <Route exact path="/inventory" component={InventoryLayout} />
      <Route path="/inventory/:id" component={ProductDetailsLayout} />
      <Route exact path="/my-products" component={InventoryLayout} />
      <Route path="/my-products/:id" component={ProductDetailsLayout} />
      <Route exact path="/orders" component={OrdersLayout} />
      <Route exact path="/accounting" component={InvoicesLayout} />
      <Route exact path="/accounting/:id" component={InvoiceDetailsLayout} />
      <Route path="/orders/:id" component={OrderDetailsLayout} />
      <Route path="/refunds/:invoiceItemId" component={RefundsDetailsPage} />
      <Route path="/purchase-order/:id" component={PurchaseOrderDetailsLayout} />
      <Route exact path="/settings" component={PreferencesLayout} />
      <Route exact path="/sync" component={ProductSyncLayout} />
      <Route exact path="/plans/check-status" component={PlanChangeConfirmationLayout} /> */}
      <Redirect to="/" />
    </Switch>
  );
}

export const Router = withRouter(RouterComponent);
