import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertCircleIcon, CheckCircleIcon } from '@shopify/polaris-icons';
import { AppCard } from '../../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../../core/components/text-container/text-container';
import { AppDisplayText } from '../../../../../../core/components/text/display-text/display-text';
import { AppTextStyle } from '../../../../../../core/components/text/text-style/TextStyle';
import { IPreferences } from '../../../../../interfaces/IPreferences';
import { getStatusSelector } from '../../../../../redux/modules/status/status.selectors';
import { ControlledRetailerAutocompleteField } from '../../inputs/controlled-retailer-autocomplete-field';
import { AppList } from '../../../../../../core/components/list/list';
import { AppControlledRadioGroup } from '../../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { MARKETPLACE_APPROVAL_STATUS, statusApi } from '../../../../../api/status.api';
import { getStatusAction } from '../../../../../redux/modules/status/status.actions';
import { SellerApplication } from './seller-application/seller-application';
import { AppBanner } from '../../../../../../core/components/feedback-indicators/banner/banner';
import { Icon } from '@shopify/polaris';
import { getPreferencesAction } from '../../../../../redux/modules/preferences/preferences.actions';

type MarketplaceSectionProps = {
  values: IPreferences;
};

export const MarketplaceSection = ({ values }: MarketplaceSectionProps) => {
  const dispatch = useDispatch();
  const { marketplaceApprovalStatus } = useSelector(getStatusSelector);
  const hasMarketplaceApproval = marketplaceApprovalStatus === MARKETPLACE_APPROVAL_STATUS.APPROVED;

  const selectedPNRetailers = useMemo(
    () => values.visibility.privateNetwork.map((rn) => rn.retailerAccount),
    [values.visibility.privateNetwork],
  );

  useEffect(() => {
    dispatch(getStatusAction());
  }, [dispatch]);

  const handleRequestMarketplaceApproval = useCallback(() => {
    statusApi
      .requestMarketplaceApproval()
      .catch(console.error)
      .finally(() => {
        dispatch(getStatusAction());
        dispatch(getPreferencesAction());
      });
  }, [dispatch]);

  return (
    <div className="marketplace-section">
      <div className="description">
        <AppDisplayText as="span">Sell on Crowdship Marketplace</AppDisplayText>
        <AppTextContainer>
          <p>
            1000+ online stores use Crowdship Marketplace to source and drop ship products.
            Instantly expose your products to online retailers, influencers, and marketers ready to
            sell your products.
          </p>
        </AppTextContainer>
      </div>
      <AppLayoutAnnotatedSection
        title="Marketplace Application"
        description="Crowdship Marketplace is a curated catalog for approved Suppliers only. For a list of restricted products, please see our Acceptable use policy."
      >
        <AppCard>
          <div className="marketplace-visibility-description">
            {marketplaceApprovalStatus === MARKETPLACE_APPROVAL_STATUS.NOT_ISSUED && (
              <SellerApplication
                handleRequestMarketplaceApproval={handleRequestMarketplaceApproval}
              />
            )}
            {marketplaceApprovalStatus === MARKETPLACE_APPROVAL_STATUS.PENDING && (
              <AppBanner icon={AlertCircleIcon}>
                Your application has been received and is pending approval. We’ll reach out with
                next steps if your store is approved to sell on Marketplace.
              </AppBanner>
            )}
            {hasMarketplaceApproval && (
              <div className="approved-wrapper">
                <Icon source={CheckCircleIcon} /> &nbsp;
                <p className="approved-text">
                  <AppTextStyle as="span">
                    You are approved to publish products to Crowdship Marketplace.
                  </AppTextStyle>
                </p>
              </div>
            )}
          </div>
        </AppCard>
      </AppLayoutAnnotatedSection>
      {hasMarketplaceApproval && (
        <AppLayoutAnnotatedSection
          title="Marketplace Retailer Approval"
          description={<AppList>{RETAILER_AUTO_APPROVE_DETAILS}</AppList>}
        >
          <AppCard>
            <AppControlledRadioGroup
              optionsDesc={[
                {
                  value: 'true',
                  label: 'Auto approve',
                  helpText: (
                    <>
                      <strong>Note:</strong> You must have Autopay enabled to automatically accept
                      new Retailers and their orders. Check Autopay Settings to accept orders from
                      Marketplace Retailers.
                    </>
                  ),
                },
                {
                  value: 'false',
                  label: 'Manual review',
                  helpText:
                    'I will manually review and approve each Retailer that requests to sell my products.',
                },
              ]}
              name={'visibility.marketplace.autoApprove'}
            />
          </AppCard>
        </AppLayoutAnnotatedSection>
      )}
      <AppLayoutAnnotatedSection
        title="Restrict Visibility"
        description="To restrict specific retailers from selling your products, add them to the list. (optional)"
      >
        <AppCard>
          <p>
            Your products are <b>not</b> available to the following Retailers:
          </p>
          <ControlledRetailerAutocompleteField
            name="visibility.marketplace.list"
            excludeOptions={selectedPNRetailers}
          />
        </AppCard>
      </AppLayoutAnnotatedSection>
    </div>
  );
};

const RETAILER_AUTO_APPROVE_DETAILS = [
  'Retailers can find your products on Crowdship Marketplace and request to sell them in their store.',
  'You can manually review each retailers request, or automatically approve all retailers to sell your products.',
];
