import { useSelector } from 'react-redux';
import { getInactiveStoreSelector } from '../../../../core/redux/modules/auth/auth.selectors';
// import { AccountNotConnectedBanner } from '../account-not-connected-banner/account-not-connected-banner';
// import { CancellationRequestedBanner } from '../cancellation-requested-banner/cancellation-requested-banner';
// import { PaymentPendingConfirmationBanner } from '../payment-pending-confirmation-banner/payment-pending-confirmation-banner';
// import { ApiUpdatesBanner } from '../../../../core/components/banners/api-updates-banner/api-updates-banner';
// import { getUpdatedApiStatusSelector } from '../../../redux/modules/status/status.selectors';
// import { useLocation } from 'react-router-dom';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';

export function AppBanners() {
  // const { pathname } = useLocation();
  const inactiveStore = useSelector(getInactiveStoreSelector);
  // const apiStatus = useSelector(getUpdatedApiStatusSelector);

  if (inactiveStore) return null;

  return (
    <>
      <div style={{ margin: '0 auto', padding: '1em 2.2rem', width: '90vw', maxWidth: '80.25rem' }}>
        <AppBanner>
          Crowdship’s new standalone application is here! This embedded Shopify app will soon be
          discontinued, and you’ll need to log at{' '}
          <a href={process.env.REACT_APP_STANDALONE_SUPPLIER_URL} target="_blank" rel="noreferrer">
            supply.crowdship.io
          </a>{' '}
          moving forward.{' '}
        </AppBanner>
      </div>
      {/* <AccountNotConnectedBanner />
      <CancellationRequestedBanner />
      <PaymentPendingConfirmationBanner />
      {!apiStatus.updatedApi && apiStatus.permissionUrl && !pathname.includes('/api-update') && (
        <ApiUpdatesBanner permissionUrl={apiStatus.permissionUrl} />
      )} */}
    </>
  );
}
