import { FormikState } from 'formik';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { validation } from '../../../../../core/helpers/validations.helper';
import { IPreferences, PAYOUT_METHOD } from '../../../../interfaces/IPreferences';
import { AchDetailsForm } from './ach-wire-details-form';
import { IPreferencesSectionProps } from './IPreferencesSectionProps';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';

export const PayoutSection = ({
  values,
  initialValues,
  resetForm,
  setFieldValue,
}: IPreferencesSectionProps & {
  initialValues: IPreferences;
  setFieldValue: (field: string, value: any) => void;
  resetForm: (nextState?: Partial<FormikState<IPreferences>> | undefined) => void;
}) => {
  return (
    <AppLayoutAnnotatedSection title="Payouts">
      <AppCard>
        <AppTextContainer>
          <p>Select a payout method below to deposit your Crowdship payouts.</p>
        </AppTextContainer>
        <br />
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: PAYOUT_METHOD.BANK_ACH_TRANSFER,
              label: 'Bank ACH Transfer',
              helpText: values.payout.payoutMethod === PAYOUT_METHOD.BANK_ACH_TRANSFER && (
                <AchDetailsForm values={values} setFieldValue={setFieldValue} />
              ),
            },
            {
              value: PAYOUT_METHOD.PAYPAL,
              label: 'PayPal',
              helpText: values.payout.payoutMethod === PAYOUT_METHOD.PAYPAL && (
                <div className="payout-details">
                  <AppControlledTextField
                    label="PayPal email"
                    placeholder=""
                    name="payout.paypalEmail"
                    validate={validation.isEmail}
                  />
                </div>
              ),
            },
          ]}
          name="payout.payoutMethod"
          onChange={(value: string) => {
            if (
              (values.payout.payoutMethod !== PAYOUT_METHOD.PAYPAL &&
                value === PAYOUT_METHOD.PAYPAL) ||
              (values.payout.payoutMethod === PAYOUT_METHOD.PAYPAL &&
                value !== PAYOUT_METHOD.PAYPAL)
            ) {
              resetForm({
                values: {
                  ...initialValues,
                },
              });
              setFieldValue('payout.payoutMethod', value);
            }
          }}
        />
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
