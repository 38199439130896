import { jwtDecode } from 'jwt-decode';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
// import { adminApi } from '../core/api/admin.api';
import { setAxiosAuthorization } from '../core/api/base.api';
import { ApiUpdatesBanner } from '../core/components/banners/api-updates-banner/api-updates-banner';
import { AppToast } from '../core/components/feedback-indicators/toast/toast';
import { AppFooterHelp } from '../core/components/footer-help/footer-help';
import { AppLink } from '../core/components/link/link';
import { AppFrame } from '../core/components/structure/frame/frame';
import { ViewAsClientNotification } from '../core/components/view-as-client-notification/view-as-client-notification';
import { ILocationStateParams } from '../core/helpers/get-title-bar.helper';
import { LoadingLayout } from '../core/layouts/loading-layout/loading-layout';
// import { setViewAsClientSettingAction } from '../core/redux/modules/admin/admin.actions';
import {
  setAuthenticatedAction,
  setHostAction,
  setIsLogged,
  setRolesAction,
  setStoreHostAction,
  setStoreActivityAction,
} from '../core/redux/modules/auth/auth.actions';
// import { ACCOUNT_ROLE } from '../core/redux/modules/auth/auth.reducer';
import {
  getHostSelector,
  isAuthenticatedSelector,
  isLoggedSelector,
} from '../core/redux/modules/auth/auth.selectors';
import './App.scss';
import { Router } from './Router';
import { authApi } from './api/auth.api';
import { AppBanners } from './components/banners/app-banners/app-banners';
import { ApiUpdateLayout } from './components/layouts/api-update-layout/api-update-layout';
import { CallbackLayout } from './components/layouts/callback-layout/callback-layout';
import { OnboardingLayout } from './components/layouts/onboarding-layout/onboarding-layout';
import { getStatusAction } from './redux/modules/status/status.actions';
import { getApiStatusSelector } from './redux/modules/status/status.selectors';

export function App() {
  const dispatch = useDispatch();
  const { boot } = useIntercom();
  const history = useHistory();
  const [error, setError] = useState('');

  const { pathname, search } = useLocation<ILocationStateParams>();
  const isCallbackPage = pathname.includes('callback') || pathname.includes('api-update');
  const query = search;

  const isLogged = useSelector(isLoggedSelector);
  const apiStatus = useSelector(getApiStatusSelector);

  const authenticated = useSelector(isAuthenticatedSelector);
  const host = useSelector(getHostSelector);

  const [archivedMessage, setArchivedMessage] = useState<string | undefined>(undefined);
  const [headersSet, setHeadersSet] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const parsedQuery = new URLSearchParams(query);
      const host = parsedQuery.get('host') as string;
      const storeHost = parsedQuery.get('shop') as string;
      if (storeHost) {
        const { data } = await authApi.authenticate(query);
        if ('accessToken' in data && data.accessToken) {
          const tokenData: any = jwtDecode(data.accessToken);
          if (!tokenData) throw new Error('Cannot parse token');
          boot({
            userId: tokenData.id,
            name: tokenData.name,
            email: tokenData.email,
            phone: tokenData.phone,
            company: {
              companyId: tokenData.customerId,
              name: tokenData.name,
              website: storeHost,
            },
          });
          setAxiosAuthorization(data.accessToken);
          dispatch(setAuthenticatedAction(data.accessToken));
          dispatch(setHostAction(host));
          dispatch(setStoreHostAction(storeHost));
          dispatch(setRolesAction(tokenData.roles));
          // TODO: add after 1110 task
          dispatch(setStoreActivityAction(false));
          // Disable admin tools for legacy
          // if (tokenData.roles.includes(ACCOUNT_ROLE.ADMIN)) {
          //   const { data } = await adminApi.getViewAsClientSetting();
          //   dispatch(setViewAsClientSettingAction(data.viewAsClient));
          //   setAxiosViewAsClient(data.viewAsClient?._id);
          // }
          setHeadersSet(true);
          dispatch(getStatusAction());
        } else if ('archived' in data) {
          setArchivedMessage(data.archived);
        } else if ('installationUrl' in data) {
          window.location = data.installationUrl;
        }
      }
    };

    if (query && query.length && !isCallbackPage) {
      checkAuthentication();
    }
  }, [dispatch, isCallbackPage, query, authenticated, boot]);

  const checkIsOldAccount = useCallback(() => {
    authApi
      .getAccountLoginOption()
      .then(({ data }) => {
        if ('selectedLoginOption' in data && !data.selectedLoginOption) {
          dispatch(setIsLogged(false));
          history.push('/onboarding');
        } else if ('error' in data) {
          dispatch(setIsLogged(false));
          setError('Unexpected error. Please, try again later');
        } else {
          dispatch(setIsLogged(true));
        }
      })
      .catch((e) => {
        console.error(e);
        setError('Unexpected error. Please, try again later');
      });
  }, [dispatch, history]);

  useEffect(() => {
    if (authenticated) {
      dispatch(setIsLogged(true));
      checkIsOldAccount();
    }
  }, [authenticated, checkIsOldAccount, dispatch]);

  if (authenticated && host && headersSet) {
    return (
      <AppProvider
        i18n={enTranslations}
        linkComponent={({ url, ref, ...rest }) => <Link to={url} {...rest} />}
      >
        <AppFrame>
          <ViewAsClientNotification />
          <AppBanners />
          {!apiStatus.updatedApi &&
            apiStatus.permissionUrl &&
            !pathname.includes('/api-update') && (
              <ApiUpdatesBanner permissionUrl={apiStatus.permissionUrl} />
            )}
          {!isLogged ? (
            <Switch>
              <Route exact path="/onboarding" component={OnboardingLayout} />
              <Redirect to="/onboarding" />
            </Switch>
          ) : (
            <Router />
          )}

          <AppFooterHelp>
            Learn more about{' '}
            <AppLink onClick={() => window.open('https://help.crowdship.io/', '_blank')} external>
              selling on Crowdship
            </AppLink>{' '}
            at the Crowdship Help Center.
          </AppFooterHelp>
          {error && <AppToast content={error} onDismiss={() => setError('')} error />}
        </AppFrame>
      </AppProvider>
    );
  }

  if (archivedMessage) return <div>{archivedMessage}</div>;

  // if user is not authorized then he has limited routing
  // with only two routes:
  // 1) home - limited, with just showing message
  // 2) oauth callback - for install process
  return (
    <>
      <Switch>
        <Route exact path="/" component={LoadingLayout} />
        <Route exact path="/oauth/callback" component={CallbackLayout} />
        <Route exact path="/api-update" component={ApiUpdateLayout} />
      </Switch>
    </>
  );
}
