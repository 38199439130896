import React from 'react';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
// import { ConfirmLayout } from '../components/layouts/authorization/confirm-layout';
// import { ConnectLayout } from '../components/layouts/authorization/connect-layout';
// import { SignInLayout } from '../components/layouts/authorization/sign-in-layout';
// import { SignUpLayout } from '../components/layouts/authorization/sign-up-layout';

// import { IntegrationsLayout } from '../components/layouts/integrations/integrations-layout';

// import { AccountLayout } from '../../core/layouts/account-layout/account-layout';
// import { DashboardLayout } from '../../shopify-retailer/components/layouts/dashboard-layout/dashboard-layout';
// import { InventoryLayout } from '../../shopify-retailer/components/layouts/inventory-layout/inventory-layout';
// import { ImportListLayout } from '../../shopify-retailer/components/layouts/import-list-layout/import-list-layout';
// import { InvoicesLayout } from '../../shopify-retailer/components/layouts/invoices-layout/invoices-layout';
// import { InvoiceDetailsLayout } from '../../shopify-retailer/components/layouts/invoice-details-layout/invoice-details-layout';
// import { MarketplaceLayout } from '../../shopify-retailer/components/layouts/marketplace-layout/marketplace-layout';
// import { OrderDetailsLayout } from '../../shopify-retailer/components/layouts/order-details-layout/order-details-layout';
// import { OrdersLayout } from '../../shopify-retailer/components/layouts/orders-layout/orders-layout';
// import { PreferencesLayout } from '../../shopify-retailer/components/layouts/preferences-layout/preferences-layout';
// import { ProductDetailsLayout } from '../../shopify-retailer/components/layouts/product-details-layout/product-details-layout';
// import { ProductSyncLayout } from '../../shopify-retailer/components/layouts/product-sync-layout/product-sync-layout';
// import { MarketplaceProductDetailsLayout } from '../../shopify-retailer/components/layouts/marketplace-product-details-layout/marketplace-product-details-layout';
// import { PurchaseOrderDetailsLayout } from '../../shopify-retailer/components/layouts/purchase-order-details-layout/purchase-order-details-layout';
// import { AdminToolsLayout } from '../../shopify-retailer/components/layouts/admin-tools-layout/admin-tools-layout';
// import { GuardedRoute } from './GuardedRoute';
// import { ROUTES } from './routes';
// import { ACCOUNT_ROLE } from '../../core/redux/modules/auth/auth.reducer';

function RouterComponent(props: RouteComponentProps) {
  return (
    <Switch>
      {/* <GuardedRoute exact path={ROUTES.ROOT} component={DashboardLayout} onlyAuthenticated />
      <GuardedRoute
        exact
        path={ROUTES.MARKETPLACE}
        component={MarketplaceLayout}
        onlyAuthenticated
      />
      <GuardedRoute
        path={ROUTES.MARKETPLACE + '/:id'}
        component={MarketplaceProductDetailsLayout}
        onlyAuthenticated
      />
      <GuardedRoute
        exact
        path={ROUTES.IMPORT_LIST}
        component={ImportListLayout}
        onlyAuthenticated
      />
      <GuardedRoute exact path={ROUTES.INVENTORY} component={InventoryLayout} onlyAuthenticated />
      <GuardedRoute
        path={ROUTES.INVENTORY + '/:id'}
        component={ProductDetailsLayout}
        onlyAuthenticated
      />
      <GuardedRoute exact path={ROUTES.ORDERS} component={OrdersLayout} onlyAuthenticated />
      <GuardedRoute
        path={ROUTES.ORDERS + '/:id'}
        component={OrderDetailsLayout}
        onlyAuthenticated
      />
      <GuardedRoute
        path={ROUTES.PURCHASE_ORDER + '/:id'}
        component={PurchaseOrderDetailsLayout}
        onlyAuthenticated
      />
      <GuardedRoute exact path={ROUTES.ACCOUNTING} component={InvoicesLayout} onlyAuthenticated />
      <GuardedRoute
        path={ROUTES.ACCOUNTING + '/:id'}
        component={InvoiceDetailsLayout}
        onlyAuthenticated
      />
      <GuardedRoute path={ROUTES.SETTINGS} component={PreferencesLayout} onlyAuthenticated />
      <GuardedRoute path={ROUTES.ACCOUNT} component={AccountLayout} onlyAuthenticated />
      <GuardedRoute path={ROUTES.SYNC} component={ProductSyncLayout} onlyAuthenticated />
      <GuardedRoute path={ROUTES.INTEGRATIONS} component={IntegrationsLayout} onlyAuthenticated />

      <GuardedRoute
        path={ROUTES.ADMIN_TOOLS}
        component={AdminToolsLayout}
        onlyAuthenticated
        requireRoles={[ACCOUNT_ROLE.ADMIN]}
      />

      <GuardedRoute path={ROUTES.CONNECT} component={ConnectLayout} onlyAuthenticated />
      <GuardedRoute path={ROUTES.SIGN_IN} component={SignInLayout} onlyUnauthenticated />
      <GuardedRoute path={ROUTES.SIGN_UP} component={SignUpLayout} onlyUnauthenticated />
      <GuardedRoute path={ROUTES.CONFIRM} component={ConfirmLayout} onlyUnauthenticated />
      <Redirect to={ROUTES.ROOT} /> */}
    </Switch>
  );
}

export const Router = withRouter(RouterComponent);
