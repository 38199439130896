import { AxiosResponse } from 'axios';
import { axiosInstance } from './base.api';
import { IAccount } from '../interfaces/IAccount';

class AccountApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/account';

  async getManagePaymentSourcesURL(): Promise<AxiosResponse<{ url: string }>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/payment-url`);
  }

  async getAccountInfo(): Promise<AxiosResponse<IAccountWithNesting>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}`);
  }

  async connectAccount(): Promise<AxiosResponse<string>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/connect`);
  }

  async disconnectAccount(): Promise<AxiosResponse<null>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/disconnect`);
  }

  async postAccountInfo(info: IAccount): Promise<AxiosResponse<IAccountWithNesting>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}`, info);
  }
}

export const accountApi = new AccountApi();

export interface IAccountWithNesting
  extends Omit<IAccount, 'line1' | 'line2' | 'city' | 'state' | 'zip' | 'country'> {
  billingAddress?: Pick<IAccount, 'line1' | 'line2' | 'city' | 'state' | 'zip' | 'countryCode'>;
}
