import React, { useCallback } from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { StatusActiveIcon } from '@shopify/polaris-icons';
import './installation-flow-banner.scss';
import { AppIcon } from '../../../../core/components/icon/icon';

interface IInstallationFlowBannerProps {
  installationSteps: {
    setupAccount: boolean;
    reviewPreferences: boolean;
    importProducts: boolean;
  };
}

export const InstallationFlowBanner = ({ installationSteps }: IInstallationFlowBannerProps) => {
  const stepAnnotation = useCallback(
    (stepNum: number, completed: boolean) =>
      completed ? <AppIcon source={StatusActiveIcon} /> : `Step ${stepNum}`,
    [],
  );

  return (
    <div className="banner">
      <AppBanner title="Welcome to Crowdship!">
        <div className="bold">
          Please take a couple minutes to get set up with these basic steps
        </div>
        <div className="steps">
          <div className="step">
            <div className="annotation">{stepAnnotation(1, installationSteps.setupAccount)}</div>
            <div className="description">
              Set up your <AppLink url="/settings?tab=subscription-plan">account</AppLink> and
              crowdship wallet
            </div>
          </div>
          <div className="step">
            <div className="annotation">
              {stepAnnotation(2, installationSteps.reviewPreferences)}
            </div>
            <div className="description">
              Review the default <AppLink url="/settings">settings</AppLink> configuration
            </div>
          </div>
          <div className="step">
            <div className="annotation">{stepAnnotation(3, installationSteps.importProducts)}</div>
            <div className="description">
              Import some <AppLink url="/marketplace">products</AppLink> you want to sell
            </div>
          </div>
        </div>
        <div className="footer">
          As soon as you complete these steps, you are ready to start accepting orders for your
          chosen Crowdship products, and Crowdship will handle the rest.
        </div>
      </AppBanner>
    </div>
  );
};
