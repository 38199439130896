import React, { ReactNode } from 'react';
import { Card, CardProps } from '@shopify/polaris';

type ICardProps = CardProps & { sections?: { title?: string; content: ReactNode }[] };

export const AppCard = ({ sections, ...props }: ICardProps) => {
  if (sections) {
    return (
      <Card {...props}>
        {sections.map((s, i) => (
          <Card key={i}>{s.content}</Card>
        ))}
      </Card>
    );
  }
  return <Card {...props} />;
};
