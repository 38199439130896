export interface ITitleBarProps {
  title: string;
  breadcrumbs: any[];
}

export const getTitleBar = (path: string, params: ILocationStateParams = {}): ITitleBarProps => {
  const paths: string[] = path.split('/');
  if (!paths.length) return { title: '', breadcrumbs: [] };
  //title is always latest element in array
  const title = paths[paths.length - 1].replace('-', ' ');
  //remove last  and first elements because they
  //have not important,
  //and all that remains will be breadcrumbs
  const breadcrumbs: any[] = paths
    .filter((str, index) => index !== 0 && index !== paths.length - 1)
    .map((str, index, array) => {
      let url = '';
      let iterationIndex = 0;
      do {
        url = url.concat(`/${array[iterationIndex]}`);

        iterationIndex++;
      } while (iterationIndex <= index);

      return {
        url,
        target: 'APP',
        content: getName({ defaultString: str.replace('-', ' '), customizedString: params[str] }),
      };
    });
  return {
    title: getName({ defaultString: title, customizedString: params[title] }),
    breadcrumbs,
  };
};

const getName = ({ defaultString, customizedString = '' }: IGetNameParams) => {
  if (customizedString === '') return getTransformedString(defaultString);
  return getTransformedString(customizedString);
};

const getTransformedString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export interface ILocationStateParams {
  [key: string]: string;
}

interface IGetNameParams {
  defaultString: string;
  customizedString?: string;
}
