import React from 'react';

import { createRoot } from 'react-dom/client';
import './index.css';
import '@shopify/polaris/build/esm/styles.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { IntercomProvider } from 'react-use-intercom';

const container = document.getElementById('root');
const root = createRoot(container!);

export const renderShopifySupplierApp = () => {
  root.render(
    <Provider store={store}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntercomProvider>
    </Provider>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};
