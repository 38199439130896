import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppStack } from '../../structure/stack/Stack';
import { AppTag } from '../../tag/tag';
import { AppButton } from '../../button/Button';
import { AppTextField } from '../text-field/text-field';

import './controlled-tag-field.scss';

interface AppControlledTagFieldProps {
  tags: string;
  name: string;
  label?: string;
  setFieldValue: (field: string, value: any) => void;
  maxLength?: number;
}

export const AppControlledTagField = ({
  tags,
  name,
  label,
  setFieldValue,
  maxLength,
}: AppControlledTagFieldProps) => {
  const [newTagValue, setNewTagValue] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>(
    tags.split(',').filter((t) => t) || [],
  );

  useEffect(() => {
    setSelectedTags(tags.split(',').filter((t) => t) || []);
  }, [tags]);

  const handleTextFieldChange = useCallback((value) => {
    setNewTagValue(value);
  }, []);

  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) => {
        setFieldValue(name, previousTags.filter((previousTag) => previousTag !== tag).join(', '));
        return previousTags.filter((previousTag) => previousTag !== tag);
      });
    },
    [setFieldValue, name],
  );

  const verticalContentMarkup = useMemo(
    () => (
      <AppStack>
        {selectedTags.map((tag) => {
          if (!tag) return null;

          return (
            <AppTag key={tag} onRemove={removeTag(tag)}>
              {tag}
            </AppTag>
          );
        })}
      </AppStack>
    ),
    [selectedTags, removeTag],
  );

  return (
    <div className="tags-field">
      <div className="tags-field--headings">
        <p>{label || ''}</p>
        <AppButton
          onClick={() => {
            setSelectedTags([]);
            setFieldValue(name, '');
          }}
        >
          Remove All Tags
        </AppButton>
      </div>
      <AppTextField
        label={false}
        value={newTagValue}
        onChange={handleTextFieldChange}
        placeholder="Add New Tag"
        autoComplete="off"
        onBlur={() => {
          if (newTagValue) {
            setNewTagValue('');
            setSelectedTags((previousTags) => [...previousTags, ...newTagValue.split(',')]);
            setFieldValue(name, [...selectedTags, newTagValue].join(', '));
          }
        }}
        verticalContent={verticalContentMarkup}
        maxLength={maxLength}
      />
    </div>
  );
};
