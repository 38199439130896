import { useEffect } from 'react';
import { AppCheckbox } from '../../../../../core/components/checkbox/checkbox';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledTagField } from '../../../../../core/components/forms/controlled-tag-field/controlled-tag-field';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { validation } from '../../../../../core/helpers/validations.helper';
import {
  DEFAULT_CANCELLATION_ACCEPT,
  onCancellationActionOptionsConstants,
  orderCreationOptionsConstants,
  orderNameOriginOptionsConstants,
  outOfStockProcessingConstants,
  SUPPLIER_ORDER_NAME_ORIGIN,
  SUPPLIER_ORDERS_TAGS_LIMIT,
} from '../../../../constants/preferences.constants';
import { IPreferences } from '../../../../interfaces/IPreferences';

type OrderSectionProps = {
  values: IPreferences;
  hasThreshold: boolean;
  onThresholdChange: (value: boolean) => void;

  setFieldValue: (field: string, value: any) => void;
};

export const OrdersSection = ({
  values,
  hasThreshold,
  onThresholdChange,
  setFieldValue,
}: OrderSectionProps) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <AppLayoutAnnotatedSection title="Orders">
      <AppCard>
        <AppControlledCheckbox
          label="Add tag(s) to orders created by Crowdship"
          name={'order.addTag'}
        />
        {values.order.addTag && (
          <AppControlledTagField
            label="Specify a custom tag(s) label (optional)"
            name={'order.customTag'}
            tags={values.order.customTag || ''}
            setFieldValue={setFieldValue}
            maxLength={SUPPLIER_ORDERS_TAGS_LIMIT}
          />
        )}
      </AppCard>
      {values.order.nameOrigin !== SUPPLIER_ORDER_NAME_ORIGIN.SHOPIFY && (
        <AppCard>
          <AppControlledCheckbox
            label="Add prefix to order numbers created by Crowdship"
            name={'order.addPrefix'}
          />
          {values.order.addPrefix && (
            <AppControlledTextField
              label="Specify a custom prefix (optional)"
              name="order.customPrefix"
              placeholder="CS-"
              validate={validation.isOrderNamePrefix}
            />
          )}
        </AppCard>
      )}
      <AppCard>
        <AppControlledRadioGroup
          optionsDesc={orderNameOriginOptionsConstants}
          name={'order.nameOrigin'}
        />
      </AppCard>
      <AppCard>
        <AppControlledRadioGroup
          optionsDesc={orderCreationOptionsConstants}
          name={'order.markingRule'}
        />
      </AppCard>
      <AppCard>
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: DEFAULT_CANCELLATION_ACCEPT.ACCEPT,
              label:
                'Accept cancellation requests automatically for line items, that are not marked fulfilled',
              helpText: (
                <div className="accept-cancellation-checkbox">
                  <AppCheckbox
                    disabled={
                      values.order.defaultCancellationAccept === DEFAULT_CANCELLATION_ACCEPT.IGNORE
                    }
                    label={
                      <>
                        <label>Accept only if total line item amount is under set threshold</label>
                        <div className="narrow-text-field" onClick={(e) => e.stopPropagation()}>
                          <AppControlledTextField
                            name={'order.cancellationAcceptThreshold'}
                            prefix={'$'}
                            type="number"
                            placeholder={'0'}
                            disabled={
                              !hasThreshold ||
                              values.order.defaultCancellationAccept ===
                                DEFAULT_CANCELLATION_ACCEPT.IGNORE
                            }
                          />
                        </div>
                      </>
                    }
                    checked={hasThreshold}
                    onChange={onThresholdChange}
                  />
                </div>
              ),
            },
            {
              value: DEFAULT_CANCELLATION_ACCEPT.IGNORE,
              label: 'Never automatically accept cancellation requests',
            },
          ]}
          name={'order.defaultCancellationAccept'}
        />
      </AppCard>
      <AppCard>
        <AppControlledRadioGroup
          optionsDesc={onCancellationActionOptionsConstants}
          name={'order.onCancellationAction'}
        />
      </AppCard>
      <AppCard>
        <AppTextStyle as="span">
          What should Crowdship do if you receive an out of stock order?
        </AppTextStyle>
        <br />
        <AppControlledRadioGroup
          optionsDesc={outOfStockProcessingConstants}
          name={'order.outOfStockProcessing'}
        />
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
