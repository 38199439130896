import { TitleBar } from '@shopify/app-bridge-react';
import { Page, PageProps } from '@shopify/polaris';

type IPageProps = PageProps & { titleBarTitle?: string };

// TitleBar requires AppBridgeProvider to be rendered and we cannot add it for the standalone app
const hideTitleBar = process.env.REACT_APP_APP_TYPE === 'STANDALONE_RETAILER';

export function AppPage({ children, titleBarTitle, title, ...props }: IPageProps) {
  return (
    <Page title={title} {...props}>
      {!hideTitleBar && <TitleBar title={titleBarTitle || title || ''} />}
      {children}
    </Page>
  );
}
