import React from 'react';
import { AppSkeletonPage } from '../feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppSkeletonBodyText } from '../feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppLayout } from '../structure/layout/layout';
import { AppLayoutAnnotatedSection } from '../structure/layout/layout-annotated-section';
import { AppCard } from '../structure/card/card';

export function AccountDetailsSkeleton() {
  return (
    <AppSkeletonPage title="Account">
      <AppLayout>
        <AppLayoutAnnotatedSection title="Business info">
          <AppCard>
            <AppSkeletonBodyText />
          </AppCard>
        </AppLayoutAnnotatedSection>
        <AppLayoutAnnotatedSection title="Contact info">
          <AppCard>
            <AppSkeletonBodyText />
          </AppCard>
        </AppLayoutAnnotatedSection>
        <AppLayoutAnnotatedSection title="Billing address">
          <AppCard>
            <AppSkeletonBodyText />
          </AppCard>
        </AppLayoutAnnotatedSection>
        <AppLayoutAnnotatedSection title="Payouts">
          <AppCard>
            <AppSkeletonBodyText />
          </AppCard>
        </AppLayoutAnnotatedSection>
        <AppLayoutAnnotatedSection title="Payment details">
          <AppSkeletonBodyText />
        </AppLayoutAnnotatedSection>
      </AppLayout>
    </AppSkeletonPage>
  );
}
