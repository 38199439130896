import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { PreferencesLayout } from '../components/layouts/preferences-layout/preferences-layout';
import { AccountLayout } from '../../core/layouts/account-layout/account-layout';
import { OrderDetailsLayout } from '../components/layouts/order-details-layout/order-details-layout';
import { OrdersLayout } from '../components/layouts/orders-layout/orders-layout';
import { OnboardingLayout } from '../components/layouts/onboarding-layout/onboarding-layout';
// import { DashboardLayout } from '../components/layouts/dashboard-layout/dashboard-layout';
// import { AdminToolsLayout } from '../components/layouts/admin-tools-layout/admin-tools-layout';
// import { PayoutsLayout } from '../components/layouts/payouts-layout/payouts-layout';
// import { PayoutDetailsLayout } from '../components/layouts/payout-details-layout/payout-details-layout';
// import { RefundsDetailsPage } from '../../core/components/refunds/refunds-details-page';
// import { ShipmentLayout } from '../components/layouts/shipment-layout/shipment-layout';
// import { ProductsLayout } from '../components/layouts/products-layout/products-layout';
import { GuardedRoute } from './GuardedRoute';

function RouterComponent(props: RouteComponentProps) {
  return (
    <Switch>
      <Route exact path="/" component={PreferencesLayout} />
      <GuardedRoute exact path="/settings" component={PreferencesLayout} />
      <GuardedRoute exact path="/account" component={AccountLayout} />
      <GuardedRoute exact path="/orders" component={OrdersLayout} />
      <GuardedRoute path="/orders/:id" component={OrderDetailsLayout} />
      <GuardedRoute path="/account/onboarding" component={OnboardingLayout} />
      {/*
      GuardedRoute exact path="/admin-tools" component={AdminToolsLayout} /> 
      <GuardedRoute exact path="/products" component={ProductsLayout} /> 
      <GuardedRoute path="/refunds/:invoiceItemId" component={RefundsDetailsPage} />
      <GuardedRoute exact path="/shipments" component={ShipmentLayout} />
      <GuardedRoute exact path="/accounting" component={PayoutsLayout} />
      <GuardedRoute exact path="/accounting/:id" component={PayoutDetailsLayout} /> */}
      <Redirect to="/" />
    </Switch>
  );
}

export const Router = withRouter(RouterComponent);
