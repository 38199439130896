import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { AutocompleteWithLoading } from '../../../../../core/components/forms/autocomplete-with-loading/autocomplete-with-loading';
import { retailerApi } from '../../../../api/retailer.api';

type RetailerAutocompleteFieldProps = {
  excludeOptions: { label: string; value: string }[];
  selectedOptions: { label: string; value: string }[];
  onSelectionChange: (selected: { label: string; value: string }[]) => void;
};

export const RetailerAutocompleteField = ({
  selectedOptions,
  onSelectionChange,
  excludeOptions,
}: RetailerAutocompleteFieldProps) => {
  const [retailerFilter, setRetailerFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [debouncedRetailerFilter] = useDebounce(retailerFilter, 500);
  const [retailerOptions, setRetailerOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (debouncedRetailerFilter) {
      setLoading(true);
      retailerApi
        .filterRetailers(debouncedRetailerFilter)
        .then((res) => {
          setRetailerOptions(
            res.data
              .filter((r) => !excludeOptions.some((o) => o.value === r._id))
              .map((r) => ({ value: r._id, label: r.name })),
          );
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRetailerOptions([]);
      setLoading(false);
    }
  }, [debouncedRetailerFilter, excludeOptions]);

  return (
    <AutocompleteWithLoading
      selectedOptions={selectedOptions}
      // Since we only send labels that are string, we will not receive any ReactNodes
      onSelectionChange={onSelectionChange as any}
      loading={loading}
      placeholder="Start typing retailer's name"
      options={retailerOptions}
      onInputChange={(value) => setRetailerFilter(value)}
    />
  );
};
