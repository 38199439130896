import { Autocomplete, Icon, BlockStack, Tag } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';

type AutocompleteWithLoadingProps = {
  options: any[];
  loading: boolean;
  label?: string;
  placeholder?: string;
  onInputChange: (value: string) => void;
  onSelectionChange: (selection: any[]) => void;
  selectedOptions: any[];
};

export const AutocompleteWithLoading = ({
  loading,
  options,
  label,
  onInputChange: onChange,
  placeholder,
  selectedOptions,
  onSelectionChange,
}: AutocompleteWithLoadingProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (value) => {
      setInputValue(value);
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      const updatedOptions = selected.map((s) => ({
        value: s,
        label: (options.find((o) => o.value === s) || selectedOptions.find((o) => o.value === s))!
          .label,
      }));
      onSelectionChange(updatedOptions);
    },
    [onSelectionChange, selectedOptions, options],
  );

  const removeTag = useCallback(
    (tagId) => () => {
      const options = selectedOptions.filter((o) => o.value !== tagId);
      onSelectionChange(options);
    },
    [selectedOptions, onSelectionChange],
  );

  const tagsMarkup = selectedOptions.map((option) => (
    <Tag key={`option${option.value}`} onRemove={removeTag(option.value)}>
      {option.label as string}
    </Tag>
  ));
  return (
    <>
      <BlockStack>{tagsMarkup}</BlockStack>
      <br />
      <Autocomplete
        allowMultiple
        options={options}
        selected={selectedOptions.map((e) => e.value)}
        onSelect={updateSelection}
        loading={loading}
        textField={
          <Autocomplete.TextField
            label={label}
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            prefix={<Icon source={SearchIcon} />}
            autoComplete="off"
          />
        }
      />
    </>
  );
};
