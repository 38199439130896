import React from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { IRetailerStatus } from '../../../interfaces/IPlan';

interface INoValidCreditCardBannerProps {
  status: IRetailerStatus | null;
}

export const NoValidCreditCardBanner = ({ status }: INoValidCreditCardBannerProps) => {
  if (!status || status.hasCard) return null;

  return (
    <div className="banner">
      <AppBanner title="No valid credit card">
        You haven't added a credit card, or your card is expired. Please,{' '}
        <AppLink url="/settings?tab=payments">go to the Settings page</AppLink> and add a valid
        credit card.
      </AppBanner>
    </div>
  );
};
